import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import SEO from '../components/seo'
import Image from '../components/image'
import CTA from '../components/cta'


const ProjectsPage = props => {
  const
    { i18n } = useTranslation('common'),
    { data } = props,
    { title } = data.sanityProjects,
    projects = data.projects.nodes;

  useEffect(() => {
    const wrapper = document.querySelector('#gatsby-focus-wrapper')

    wrapper.style.backgroundColor = "#F0EFED"
  })

  const getCursorPosition = (e) => {
    let
      x = e.clientX,
      y = e.clientY;
    const root = document.documentElement;

    root.style.setProperty('--mouse-x', (x + 12) + "px");
    root.style.setProperty('--mouse-y', (y - 4) + "px");
  }

  return (
    <>
      <SEO title="Projects" lang={props.pageContext.language} />
      <div className='grid title-container' data-scroll-section>
        <h1
        data-scroll
          className="page-title"
          style={{maxWidth: '720px'}}
          data-sal='fade' data-sal-duration='750'
          data-sal-easing='ease'
        >
          {title.translate}
        </h1>
      </div>

      <div className="projects grid" data-scroll-section>
        {projects.map(project => {
          const { title, title2, slug, cover, cover2, _createdAt } = project
          return(
            <div className='project' key={_createdAt} onMouseMove={(e) => getCursorPosition(e)} role="button" tabIndex={0} data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
              <Link  to={`/${i18n.language}/portfolio/${slug.current}`}>
                <Image className="upper" fluid={cover?.asset.fluid}/>
                <Image className="lower" fluid={cover2?.asset.fluid}/>
              </Link>
              <span className="title">{`${title} ${title2}`}</span>
            </div>
          )
        })}

      </div>

      <CTA title="about"/>
    </>
  )
}

export const query = graphql`
  query Projects($language: String) {
    sanityProjects {
      title {
        translate(language: $language)
      }
    }
    projects: allSanityProject(sort: {fields: _createdAt, order: DESC}, limit: 14) {
      nodes {
        _createdAt
        title
        title2
        slug {
          current
        }
        cover {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        cover2 {
          asset {
            fluid {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
